<template>
  <div>
    <div ref="fpEditor" id="fp-editor-container" style="height: calc(100% - 85px);">
    </div>
    <v-fab-transition>
      <v-btn v-if="fpEditorLoaded"
             color="primary"
             fab
             fixed
             bottom
             right
             @click="importToCart"
             :loading="isLoading"
             :disabled="!canImportToCart">
        <v-icon>$putInCart</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import {EventBus} from '@/application/EventBus.js';
import {importFloorplanner} from '@/modules/configurator/api';
import {initializeFloorplanner, loadScripts} from '@/modules/configurator/util/floorplanner.js';
import {mapGetters} from 'vuex';
import store from "@/application/store";

export default {
  name: 'configuratorView',
  data() {
    return {
      canImportToCart: true,
      isLoading: false,
      fpEditorLoaded: false,
      fpEditor: null,
      settings: {
        useMetric: true,
        templateId: process.env?.VUE_APP_FLOORPLANNER__TEMPLATE_ID,
        user: {
          id: store.getters["client/getFloorPlannerCredentials"].floorPlannerUserId,
          permissions: ['save', 'no-back-to-dashboard', 'favorites', 'ntl'],
          auth_token: store.getters["client/getFloorPlannerCredentials"].floorPlannerAuthToken
        },
      },
      customStyle: {
        light: '#69be28',
        dark: '#69be28'
      },
    };
  },
  computed: {
    ...mapGetters('settings', {
      floorplannerId: 'getFloorplannerId',
      storeId: 'getCurrentStoreId'
    })
  },
  watch: {
    async storeId() {
      await this.initializeFloorPlanner();
    },
    '$store.state.configurator.floorplannerLoaded': {
      async handler(val) {
        if (val) {
          await this.$nextTick();

          await this.initializeFloorPlanner()
          this.fpEditor.onUpdated = this.handleFloorplanUpdate;
          this.fpEditorLoaded = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    if (!this.$store.getters['configurator/isFloorplannerLoaded']) {
      try {
        await loadScripts;
        this.$store.dispatch('configurator/setFloorplannerLoaded');
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.configuratorLoad')]),
        });
      }
    } else {
      await this.initializeFloorPlanner();
    }
  },
  async beforeRouteLeave(to, from, next) {
    await ReactDOM.unmountComponentAtNode(document.getElementById('fp-editor-container'));
    next();
  },
  methods: {
    async importToCart() {
      this.isLoading = true;
      try {
        this.fpEditor.save();
        await importFloorplanner(this.storeId, {floorplannerProjectId: this.floorplannerId});
        this.$router.push({name: 'shoppingCart'});
      } catch {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.configuratorImport')]),
        });
      }
      this.isLoading = false;
      this.canImportToCart = false;
    },
    handleFloorplanUpdate() {
      if (!this.canImportToCart) this.canImportToCart = true;
    },
    async initializeFloorPlanner() {
      console.log(this.settings);
      this.fpEditor = await initializeFloorplanner('#fp-editor-container', {
        ...(['development', 'vue_test'].includes(process.env.VUE_APP_ENV || process.env.NODE_ENV) && {apiDomain: process.env?.VUE_APP_FLOORPLANNER__API}),
        projectId: this.floorplannerId,
        ...this.settings,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .fp___loading__background {
    z-index: 0;
  }

  .fp___loading__logo svg:first-child path:first-child, .fp___loading__back, .fp___loading__spinning, .fp___loading__door, .fp___loading__window, .fp___loading__roof {
    fill: #69be28;
  }

  .fp___loading__back, {
    fill: #2c8800;
  }

  .fp___root {
    height: calc(100vh - 85px);

    --light: rgb(247, 247, 247);
    --sidebar-color: #69be28;
    --dark: #69be28;
    --dark-darkened: #2c8800;
    --dark-lightened: #a3f762;

    .fp___topbar__nav {
      height: calc(100vh - 85px);
    }

    .fp___topbar .fp___floor-dropdown-and-panel-container .fp___new-floor-dropdown-head {
      z-index: 150;
    }
  }
}
</style>
